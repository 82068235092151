import React, { Component } from 'react';
import PressInfo from './PressInfo';
import ContactForm from './ContactForm';

class Container extends Component {
  constructor(){
    super();
    this.state = {
      pressid    : ``,
      press_title: ``,
      press_src  : ``,
      press_referrer: document.referrer.split('/')[2] ? document.referrer.split('/')[2] : ``
    }
  }

  retrieveInfo = data => this.setState({
    pressid    : data.pressid,
    press_title: data.press_title,
    press_src  : data.press_src
  })
  render() {
    return (
      <div className="container mt-5">
        <div className="row mb-5">
        <PressInfo returnInfo={this.retrieveInfo}/> 
        <ContactForm data={this.state}/>
        </div>
      </div>
    )
  }  
}

export default Container;