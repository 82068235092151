
import React, { Component } from 'react';
import Transition from 'react-animate-height';
import ReCAPTCHA from 'react-google-recaptcha';
import { Mask, Row, Button, View, Container } from 'mdbreact';
import firebase from './fire';

class HomePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mainheight    : `auto`,
      contactheight : 0,
      success       : true,
      name          : ``,
      email         : ``,
      message       : ``,
      org           : ``,
    }
    this.recaptchaRef = React.createRef();
  }
  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  onChange = value => {
    if(value){
      this.setState({
        success: false
      })
    }
  }
  toggle = () => {
    const { mainheight } = this.state;
    const { contactheight } = this.state;
    this.setState({
      mainheight: mainheight === 0 ? 'auto' : 0,
      contactheight: contactheight === 0 ? 'auto' : 0,
    });
  };
  sendEmail = () => {
    return new Promise((resolve, reject) => {
      const func = firebase.functions();
      let invokeSendEmail = func.httpsCallable(`sendEmail`);
      this.setState({
        message : this.state.message
      })
      let emailInfo = {
        senderInfo    : this.state,
      };
      invokeSendEmail(emailInfo).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
      
    })
  }
  submitContact = e => {
    e.preventDefault();
    if(!this.state.success){
      this.sendEmail()
        .then(email => {
          this.setState({
            success : true,
            sent    : true
          });
        }).catch(err => {
          // console.log(err.code);
          // console.log(err.message);
          // console.log(err.details);
        });
    }
  };
  render(){
    const { contactheight } = this.state;
    const { mainheight } = this.state;
    let btnToggle = this.state.success;
    return (
      <div id="apppage">
        <View>
          <Mask className="d-flex justify-content-center align-items-center gradient">
            <Container>
              <Transition
                duration={ 250 }
                height={ mainheight } // see props documentation bellow
              >
              <Row className="mb-5">
                <img src="/img/release-contact-full-logo-lg.png" alt="release-contact-logo" className="img-fluid mx-auto d-block"/>
                <div className="white-text text-center text-md-center col-12 mt-xl-5 mb-5">
                  <div className="col-12 col-md-6 col-md-8 mx-auto mb-5">
                    <h6 className="mb-4">ReleaseContact is a white-labelled spam protection solution for the press release newswires and content distribution services. ReleaseContact automatically hides your clients' email while ensuring that they are still reachable by their potential prospects. SSL secured, ReleaseContact encrypts all incoming and outgoing messages so your clients' privacy are always guarded.</h6>
                  </div>
                  <div className="fixed-bottom col-2 mx-auto d-flex flex-column" style={{marginBottom: "100px"}}>
                    <div className="mx-auto"><img style={{width:`150px`, height: `200px`}} src="/img/ScrollArrowAnimate.gif" alt="arrow"/></div>
                    <Button outline color="white" onClick={this.toggle}>Contact Us</Button>
                  </div>
                </div>
              </Row>
              </Transition>
              <Transition
                duration={ 500 }
                height={ contactheight }
              >
              <Row className="mb-5 d-flex justify-content-center">
              <div className="home-contact col-6 p-3">
                <a className="d-flex justify-content-end" onClick={this.toggle}><i className="fa fa-close rc-blue"/></a>
                <div className="py-4 px-5">
                <form onSubmit={this.submitContact}>
                  <p className="h4 text-center mb-4 rc-blue"><strong>Contact Us</strong></p>
                  <div className = "rc-fields mb-3 px-4 py-2">
                    <label htmlFor="FormName" className= "black-text rc-label mb-0 mr-3">Name <span className="red-text">*</span></label>
                    <input type = "text"
                    id = "FormName"
                    name = "name"
                    placeholder ="Your name"
                    className = "rc-textbox w-75 float-right"
                    onChange = {this.updateInput}
                    value = {this.state.name}
                    required/>
                  </div>
                  <div className="rc-fields mb-3 px-4 py-2">
                    <label htmlFor="FormEmail" className="black-text rc-label mb-0 mr-3">Email <span className="red-text">*</span></label>
                    <input type = "email"
                    id = "FormEmail"
                    name = "email"
                    placeholder="Your e-mail address"
                    className="rc-textbox w-75 float-right"
                    onChange = {this.updateInput}
                    value = {this.state.email}
                    required/>
                  </div>
                  <div className="rc-fields mb-3 px-4 py-2">
                    <label htmlFor = "FormOrg" className = "black-text rc-label mb-0 mr-0">Organization <span className="red-text">*</span></label>
                    <input type = "text"
                    id = "FormOrg"
                    name = "org"
                    placeholder="Organization Name"
                    className="rc-textbox w-75 float-right"
                    onChange = {this.updateInput}
                    value = {this.state.org}
                    />
                  </div>
                  <div className="rc-fields mb-3 px-4 py-2">
                    <label htmlFor="FormMessage" className="black-text rc-label mr-3">Message <span className="red-text">*</span></label>
                    <textarea type = "text"
                    id = "FormMessage"
                    name = "message"
                    placeholder="Your email message"
                    className="rc-textbox w-100"
                    rows = "10"
                    onChange = {this.updateInput}
                    value = {this.state.message} required>
                    </textarea>
                  </div>
                  <div className="d-flex justify-content-center">
                    <ReCAPTCHA
                      ref={this.recaptchaRef}
                      sitekey={process.env.REACT_APP_RECAPTCHA}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="text-center mt-4">
                    <button className="btn rc-submit w-100 py-3" type="submit" disabled={btnToggle}>
                    <strong>{this.state.sent ? `Sent` : `Send`}</strong>
                    <i className="fa fa-paper-plane-o ml-2"></i>
                    </button>
                  </div>
                </form>
                </div>
              </div>
              </Row>
              </Transition>
            </Container>
          </Mask>
        </View>
      </div>
    );
  }
}

export default HomePage;
