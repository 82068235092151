import React from 'react';
import ReactDOM from 'react-dom';
import 'font-awesome/css/font-awesome.min.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './App.css';
import Container from './Container';
import HomePage from './HomePage';
// import registerServiceWorker from './registerServiceWorker';

let path = window.location.pathname.split(`/`);
if(Number.isInteger(parseInt(path[1], 10))){
  ReactDOM.render(<Container />, document.getElementById('root'));
} else {
  ReactDOM.render( <HomePage /> , document.getElementById('root'));
}
// registerServiceWorker();
