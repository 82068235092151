import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';

let config = { /* COPY THE ACTUAL CONFIG FROM FIREBASE CONSOLE */
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID
};
firebase.initializeApp(config);

export default firebase;