import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import firebase from './fire';

class ContactForm extends Component {
  constructor(){
    super();
    this.state = {
      name    : ``,
      email   : ``,
      phone   : ``,
      subject : ``,
      message : ``,
      success : true,
      sent    : false
    };
    this.recaptchaRef = React.createRef();
  }
  onChange = value => {
    if(value){
      this.setState({
        success: false
      })
    }
  }
  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  pushDb = () => {
    return new Promise((resolve, reject) => {
      const db = firebase.firestore();
      db.settings({timestampsInSnapshots: true});
      let contact_data = {
        name     : this.state.name,
        phone    : this.state.phone,
        subject  : this.state.subject,
        message  : this.state.message,
        created  : firebase.firestore.FieldValue.serverTimestamp(),
        pressid  : this.props.data.pressid,
        referrer_domain: this.props.data.press_referrer
      };
      db.collection(`press`).doc(`${this.props.data.pressid}`)
        .collection(`email`).doc(`${this.state.email}`)
        .set(contact_data)
        .then(res => {
          resolve(res);
        }).catch(err => {
          reject(err);
        });
    })
  }
  sendEmail = () => {
    return new Promise((resolve, reject) => {
      const func = firebase.functions();
      let invokeSendEmail = func.httpsCallable(`sendEmail`);
      let emailInfo = {
        senderInfo    : this.state,
        recipientInfo : true,
        pressInfo     : {
          pressid: this.props.data.pressid,
          press_title: this.props.data.press_title,
          press_src: this.props.data.press_src
        }
      };
      invokeSendEmail(emailInfo).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
      
    })
  }
  addContacts = e => {
    e.preventDefault();
    if(!this.state.success){
      Promise.all([this.pushDb(), this.sendEmail()])
        .then(([db, email]) => {
          this.setState({
            success: true,
            sent   : true
          });
        }).catch(err => {
          // console.log(err.code);
          // console.log(err.message);
          // console.log(err.details);
        });
    }
  };
  render() {
    let btnToggle = this.state.success;
    let buttonClass = `btn rc-submit w-100 py-3`;
    let buttonText = `Submit`;
    if(this.state.sent){
      buttonClass += ` success-color`;
      buttonText = `Sent`;
    }
    return (
      <div className="Right col-12 col-lg-7">
        <div className="rc-border rc-blue p-5 ml-2 h-100">
          <form onSubmit={this.addContacts}>
            <p className="h4 text-center mb-4 rc-blue"><strong>Email Information</strong></p>
            <div className = "rc-fields mb-3 px-4 py-2">
              <label htmlFor="FormName" className= "black-text rc-label mb-0 mr-3">Name <span className="red-text">*</span></label>
              <input type = "text"
              id = "FormName"
              name = "name"
              placeholder ="Your name"
              className = "rc-textbox w-75 float-right"
              onChange = {this.updateInput}
              value = {this.state.name}
              required/>
            </div>
            <div className="rc-fields mb-3 px-4 py-2">
              <label htmlFor="FormEmail" className="black-text rc-label mb-0 mr-3">Email <span className="red-text">*</span></label>
              <input type = "email"
              id = "FormEmail"
              name = "email"
              placeholder="Your e-mail address"
              className="rc-textbox w-75 float-right"
              onChange = {this.updateInput}
              value = {this.state.email}
              required/>
            </div>
            <div className = "rc-fields mb-3 px-4 py-2">
              <label htmlFor="FormPhone" className="black-text rc-label mb-0 mr-3">Phone no.</label>
              <input type = "text"
              id = "FormPhone"
              name = "phone"
              placeholder="Your phone number"
              className="rc-textbox w-75 float-right"
              onChange = {this.updateInput}
              value = {this.state.phone}
              />
            </div>
            <div className="rc-fields mb-3 px-4 py-2">
              <label htmlFor = "FormSubject" className = "black-text rc-label mb-0 mr-3"> Subject <span className="red-text">*</span></label>
              <input type = "text"
              id = "FormSubject"
              name = "subject"
              placeholder="Email Headline"
              className="rc-textbox w-75 float-right"
              onChange = {this.updateInput}
              value = {this.state.subject}
              required/>
            </div>
            <div className="rc-fields mb-3 px-4 py-2">
              <label htmlFor="FormMessage" className="black-text rc-label mr-3">Message <span className="red-text">*</span></label>
              <textarea type = "text"
              id = "FormMessage"
              name = "message"
              placeholder="Your email message"
              className="rc-textbox w-100"
              rows = "10"
              onChange = {this.updateInput}
              value = {this.state.message} required>
              </textarea>
            </div>
            <div className="d-flex justify-content-center">
              <ReCAPTCHA
                ref={this.recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA}
                onChange={this.onChange}
              />
            </div>
            <div className="text-center mt-4">
              <button className={buttonClass} type="submit" disabled={btnToggle}>
              <strong>{buttonText}</strong>
              <i className="fa fa-paper-plane-o ml-2"></i>
              </button>
            </div>
            <div className="text-center mt-4"></div>
          </form>
          <div className="rc-badges my-3">
            <a href="https://safeweb.norton.com/report/show_mobile?url=send.releasecontact.com">
            <img src="/img/logo-master-norton.png" alt="norton secure" className="rc-badge mx-3"/>
            </a>
            <a href="https://www.mcafeesecure.com/verify?host=send.releasecontact.com">
            <img src="/img/mcafee-secure-trustmark.svg" alt="mcafee secure" className="rc-badge mx-3"/>
            </a>
          </div>
          <div className="d-flex justify-content-center">
            <p className="subtitle grey-text"> Your message is SSL encrypted and secured by ReleaseContact.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactForm;
