import React, { Component } from 'react';
import request from 'request-promise';

class PressInfo extends Component {
  constructor(){
    super();
    let path = window.location.pathname.split(`/`);
    this.state = {
      pressid     : path[1],
      post_title  : '',
      post_excerpt: '',
      post_meta   : {},
    };
  }
  componentDidMount(){
    request.post(process.env.REACT_APP_API, {form: {id: this.state.pressid}})
      .then(body => JSON.parse(body))
      .then(press => {
        let referrer = document.referrer.split('/')[2]
        if(referrer === `www.reuters.com` || referrer === `www.vcnewsnetwork.com`) {
          press.post_meta.src_org = `VCNN`;
        }
        this.setState({
          pressid     :press.pressid,
          post_title  :press.post_title,
          post_excerpt:press.post_excerpt,
          post_meta   :press.post_meta
        });
        this.props.returnInfo({
          pressid    : press.pressid,
          press_title: press.post_title,
          press_src  : press.post_meta.src_org
        });    
      })
  }
  render() {
    return (
      <div className="Left col-12 col-lg-5">
      {this.state.post_meta &&
      <div>
        <div className="d-flex justify-content-center mt-3">
          <img src="/img/release-contact-full-logo.png" alt="releasecontact-logo"></img>
        </div>
        <div className="divider w-100 my-5"></div>
        <p className = "rc-blue subtitle"> You're contacting media contact of this press release:</p>
        <h4 className="title my-4">{this.state.post_title}</h4>
        <p className="summary mb-5">{this.state.post_excerpt}</p>
        <div className="divider w-100 my-5"></div>
        {this.state.post_meta.c_name &&
        <div className="d-flex align-items-center mb-3">
          <div className="mr-4"><img src="/img/avatar.png" alt="avatar"></img></div><p className="mt-1 mb-0">{this.state.post_meta.c_name}</p>
        </div>
        }
        {this.state.post_meta.c_org &&
        <div className="d-flex align-items-center mb-3">
          <div className="mr-4"><img src="/img/linkedin.png" alt="linkedin"></img></div><p className="mt-1 mb-0">{this.state.post_meta.c_org}</p>
        </div>
        }
        {this.state.post_meta.c_site &&
        <div className="d-flex align-items-center mb-3">
          <div className="mr-4"><img src="/img/browser.png" alt="browser"></img></div><p className="mt-1 mb-0">{this.state.post_meta.c_site}</p>
        </div>
        }
        {this.state.post_meta.c_phone &&
        <div className="d-flex align-items-center mb-3">
          <div className="mr-4"><img src="/img/phone.png" alt="phone"></img></div><p className="mt-1 mb-0">{this.state.post_meta.c_phone}</p>
        </div>
        }
        {this.state.post_meta.c_address &&
        <div className="d-flex align-items-center mb-3">
          <div className="mr-4"><img src="/img/location.png" alt="location"></img></div><p className="mt-1 mb-0">{this.state.post_meta.c_address}</p>
        </div>
        }
        <div className = "divider w-100 my-5"></div>
        {this.state.post_meta.src_org &&
        <div className="d-flex justify-content-center">
          <p className="subtitle grey-text">This press release is issued by {this.state.post_meta.src_org}</p>
        </div>
        }
      </div>
      }
      </div>
    )
  }
}

export default PressInfo;